<template>
    <div>
      <b-row>
              <b-col lg="6" md="6" sm="6" class="heading-title">
                  <h4> Revenue List</h4>
                  <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
                      <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
                      <b-breadcrumb-item to="/Finance/all_revenue"> Revenue  </b-breadcrumb-item>
                      <b-breadcrumb-item active>View</b-breadcrumb-item>
                  </b-breadcrumb>
              </b-col>
              <b-col lg="6" md="6" sm="6" class="btns-header"> 
                  
              </b-col>
          </b-row>
      <br>
        <b-row>
          <b-col lg="3" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Total Booked</span>
                  <h4>{{ total.total_booking }} <span class="text-success fs-10">10%</span></h4>
                </div>
                <div class="ml-auto">
                 <div>
                    <span class="display-4 text-info fs-5">
											<i class="mdi mdi-book"></i>
										</span>
                 </div>
                </div>
              </div>
              <div class="progress w-100" style="height:4px">
                <div class="progress-bar bg-success" role="progressbar" style="width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </b-card>
          </b-col>
          <b-col lg="3" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>Total Invoice</span>
                  <h4> {{ total.invoice_generated }} <span class="text-success fs-10">10%</span></h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <span class="display-4 text-success fs-5">
											<i class="mdi mdi-book"></i>
										</span>
                  </div>
                </div>
              </div>
              <div class="progress w-100" style="height:4px">
                <div class="progress-bar bg-success" role="progressbar" style="width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </b-card>
          </b-col>
            <b-col lg="3" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>VB Invoice Generated</span>
                  <h4>{{ total.total_vb_invoice }} <span class="text-success fs-10">10%</span></h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <span class="display-4 text-warning fs-5">
											<i class="mdi mdi-book"></i>
										</span>
                  </div>
                </div>
              </div>
              <div class="progress w-100" style="height:4px">
                <div class="progress-bar bg-success" role="progressbar" style="width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </b-card>
          </b-col> 
          
          <b-col lg="3" cols="12" md="12" class="d-flex align-items-stretch">
            <b-card class="mb-4 w-100">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <span>VB Paid Invoice</span>
                  <h4>{{ total.total_paid_vb_invoice }}  <span class="text-success fs-10">10%</span></h4>
                </div>
                <div class="ml-auto">
                   <div>
                    <span class="display-4 text-primary fs-5">
											<i class="mdi mdi-book"></i>
										</span>
                  </div>
                </div>
              </div>
              <div class="progress w-100" style="height:4px">
                <div class="progress-bar bg-success" role="progressbar" style="width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </b-card>
          </b-col>
    </b-row>



    <b-row>
        <b-col md="6">
<b-card class="mb-2" >
  <b-card-header header-tag="nav">
    <div class="d-flex justify-content-between">
      <div>
        <h4>Booking</h4>
      </div>
      <div class="d-flex ">
        <input type="date" class="form-control " v-model="from_date">  <input type="date" class="form-control ml-2 " v-model="to_date" :min="from_date">
        <button class="btn btn-info ml-1 btn-sm" :disabled="from_date=='' || to_date==''" @click="apply_filter">Apply</button>
      </div>
    </div>
    
  </b-card-header>
       <vue-apex-charts type="bar"  ref="booking_charts" height="350" :options="chartOptions" :series="series"></vue-apex-charts>

</b-card>
        </b-col>
        <b-col md="6">
            <b-card class="mb-2"  >
              <b-card-header header-tag="nav">
                <h4>Invoice Generated</h4>
  </b-card-header>
              <vue-apex-charts  ref="booking_charts1" type="bar" height="350" :options="chartOptions1" :series="series1"></vue-apex-charts>
            </b-card>
        </b-col>
        <b-col md="6">
            <b-card class="mb-2"  >
              <b-card-header header-tag="nav">
                <h4>VB Invoice Generated</h4>
  </b-card-header>
              <vue-apex-charts   ref="booking_charts2" type="bar" height="350" :options="chartOptions2" :series="series2"></vue-apex-charts>
            
            </b-card>
        </b-col>
        <b-col md="6">
            <b-card  class="mb-2" >
              <b-card-header header-tag="nav">
                <h4>VB invoice</h4>
  </b-card-header>
              <vue-apex-charts   ref="booking_charts3" type="bar" height="350" :options="chartOptions3" :series="series3"></vue-apex-charts>
            
            </b-card>
        </b-col>
    </b-row>

    <!--<b-card no-body>
      <b-tabs pills card  nav-wrapper-class="w-10" class="border-bottom-2">
          <b-tab  class=""   :active="url_name=='/Finance/list_revenue/'+vendor_id"  @click="$router.push('/Finance/list_revenue/'+vendor_id)" >
              <template #title>
                  <strong>Total Booked  (<i>{{ total.total_booking }}</i> )</strong>
              </template>
              <input type="year"/>
              <transition name="fade-transform" mode="out-in">
               
                  <router-view/>
              </transition>
          </b-tab>
  
          <b-tab  class="mt-3"  :active="url_name=='/revenue/event_pending/'+vendor_id"  @click="$router.push('/revenue/event_pending/'+vendor_id)" >
              <template #title>
                  <strong> Event Pending (<i>{{ total.event_pending }}</i> )</strong>
              </template>
              <transition name="fade-transform" mode="out-in">
                  <router-view/>
              </transition>
          </b-tab>
  
          <b-tab  class="mt-3" :active="url_name=='/revenue/event_completed/'+vendor_id"  @click="$router.push('/revenue/event_completed/'+vendor_id)" >
              <template #title>
                  <strong> Event Completed (<i>{{ total.event_completed }}</i> )</strong>
              </template>
              <transition name="fade-transform" mode="out-in">
                  <router-view/>
              </transition>
          </b-tab>
  
  
  
          <b-tab  class="mt-3" :active="url_name=='/revenue/total_invoice/'+vendor_id"  @click="$router.push('/revenue/total_invoice/'+vendor_id)" >
              <template #title>
                  <strong> Total Invoiced (<i>{{ total.invoice_generated }}</i> )</strong>
              </template>
              <transition name="fade-transform" mode="out-in">
                  <router-view/>
              </transition>
          </b-tab>
  
  
          <b-tab  class="mt-3" :active="url_name=='/revenue/vb_generated_invoice/'+vendor_id"  @click="$router.push('/revenue/vb_generated_invoice/'+vendor_id)" >
              <template #title>
                  <strong> VB Generated Invoice (<i>{{ total.total_vb_invoice }}</i> )</strong>
              </template>
              <transition name="fade-transform" mode="out-in">
                  <router-view/>
              </transition>
          </b-tab>
  
  
          <b-tab  class="mt-3" :active="url_name=='/revenue/vb_paid_invoice/'+vendor_id"  @click="$router.push('/revenue/vb_paid_invoice/'+vendor_id)" >
              <template #title>
                  <strong>  VB Paid Invoice (<i>{{ total.total_paid_vb_invoice }}</i> )</strong>
              </template>
              <transition name="fade-transform" mode="out-in">
                  <router-view/>
              </transition>
          </b-tab>
  
  
          <b-tab  class="mt-3" :active="url_name=='/revenue/vb_pending_invoice/'+vendor_id"  @click="$router.push('/revenue/vb_pending_invoice/'+vendor_id)" >
              <template #title>
                  <strong>  VB Pending Invoice (<i>{{ total.total_pending_vb_invoice }}</i> )</strong>
              </template>
              <transition name="fade-transform" mode="out-in">
                  <router-view/>
              </transition>
          </b-tab>


       
  
      </b-tabs>
      </b-card>-->
  
  
     
  </div>
  </template>
  
  <script>
  import axios from "axios";
  import VueApexCharts from "vue-apexcharts";
   import { mapState } from "vuex";
  import helpers from "../../../mixins/helpers";
  export default {
      mixins: [helpers],
      data:() => ({
          url_name:'',
          vendor_id:'',
          month:[],
          total:[],
          from_date:'',
          to_date:'',


          /* Pending */
          series: [{
            name: 'Total Booking',
            data: []
            
          }, {
            name: 'Pending Event',
            data: []
          }, {
            name: 'Compeleted Event',
            data: []
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                borderRadius: 5,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              },
            },
            dataLabels: {
              formatter: function (val) {
                if(val!=0)
                {
                  return val ;
                }
               
              },
              offsetY: -15,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
              
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              //categories: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov','Dec'],
              categories:[],
            },
            yaxis: {
              title: {
                text: 'Total Booking'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return  val
                }
              }
            }
          },
          /* Pending */
          
          /* Invoiced */
          series1: [{
            name: 'Total Invoice',
            data: []
            
          }],
          chartOptions1: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '25%',
                endingShape: 'rounded',
                borderRadius: 5,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              },
            },
            dataLabels: {
              formatter: function (val) {
                if(val!=0)
                {
                  return val ;
                }
              },
              offsetY: -15,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
              
            },
            stroke: {
              show: true,
              width: 1,
              colors: ['transparent']
            },
            xaxis: {
              categories: [],
            },
            yaxis: {
              title: {
                text: 'Total Invoice'
              },
             
            
            },
            
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "$ " + val + " thousands"
                }
              }
            }
          },
          /* Invoice */
          
          /* Invoiced */
          series2: [{
            name: 'Total VB Invoice Generated',
            data: []
            
          }],
          chartOptions2: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '25%',
                endingShape: 'rounded',
                borderRadius: 5,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              },
            },
           
           /*  dataLabels: {
              enabled: false,
              
            }, */
            dataLabels: {
              formatter: function (val) {
                if(val!=0)
                {
                  return "Rs."+val ;
                }
              },
              offsetY: -15,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            stroke: {
              show: true,
              width: 1,
              colors: ['transparent']
            },
            xaxis: {
              categories: [],
            },
            yaxis: {
              title: {
                text: 'Total VB Invoice Generated'
              },
              
            
            },
            
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "Rs."+val ;
                }
              }
            }
          },
          /* Invoice */
          
          /* Invoiced */
          series3: [{
            name: 'Pending VB Invoice',
            data: []
            
          },
          {
            name: 'Paid VB Invoice',
            data: []
            
          }],
          chartOptions3: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                borderRadius: 5,
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              },
            },
            dataLabels: {
              formatter: function (val) {
                if(val!=0)
                {
                  return "Rs."+val ;
                }
              },
              offsetY: -15,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
              
            },
            stroke: {
              show: true,
              width: 1,
              colors: ['transparent']
            },
            xaxis: {
              categories: [],
            },
            yaxis: {
              title: {
                text: 'Total VB Invoice Generated'
              },
              
            
            },
            
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "Rs."+val ;
                }
              }
            }
          },
          /* Invoice */
      }),
   components:
   {
    VueApexCharts
   },
    methods:
    {
      async revenue_totals()
      {
          axios.post("/admin/revenue_totals",{
              vendor_id:this.vendor_id
          }).then((resp) => {
  this.total =resp.data;
          })
      },
      async vendor_getMonthlyData()
      {
        

          axios.post("/admin/vendor_getMonthlyData",{
              vendor_id:this.vendor_id,
              from_date:this.from_date,
              to_date:this.to_date
          }).then((resp) => {
  this.month =resp.data.month;

              for (let l = 0; l < this.month.length; l++) 
							{
								this.$refs.booking_charts.refresh(
								( this.chartOptions.xaxis.categories.push(resp.data.month[l])),
								( this.series[0].data.push(resp.data.total_booking[l])),
								( this.series[1].data.push(resp.data.event_pending[l])),
								( this.series[2].data.push(resp.data.event_completed[l]))
								);
                this.$refs.booking_charts1.refresh(
								( this.chartOptions1.xaxis.categories.push(resp.data.month[l])),
								( this.series1[0].data.push(resp.data.invoice_generated[l]))
								);
                this.$refs.booking_charts2.refresh(
								( this.chartOptions2.xaxis.categories.push(resp.data.month[l])),
								( this.series2[0].data.push(resp.data.total_vb_invoice[l]))
								);
                this.$refs.booking_charts3.refresh(
								( this.chartOptions3.xaxis.categories.push(resp.data.month[l])),
                ( this.series3[0].data.push(resp.data.total_pending_vb_invoice[l])),
                ( this.series3[1].data.push(resp.data.total_paid_vb_invoice[l]))
                
								//( this.series[0].data.push(resp.data.bookings.getMonthlyData.amount[l]))
								);
								
							}
          })
      },
      apply_filter()
      {
        this.series[0].data = [];
        this.series[1].data = [];
        this.series[2].data = [];
        this.chartOptions.xaxis.categories=[];


        this.series1[0].data = [];
        this.chartOptions1.xaxis.categories=[];

        this.series2[0].data = [];
        this.chartOptions2.xaxis.categories=[];

        this.series3[0].data = [];
        this.series3[1].data = [];
        this.chartOptions3.xaxis.categories=[];

        this.vendor_getMonthlyData();
      }
      
    },
    mounted()
    {
      this.url_name = window.location.pathname;
      this.vendor_id = this.$route.params.id;
      this.revenue_totals();
      this.vendor_getMonthlyData();
    },
    computed: {
      ...mapState(['set_booking_option']),
     },
     created() {
      this.url_name = window.location.pathname;
      },
  } 
  </script>
  
  <style>
  .fs-10
  {
    font-size:10px;
  }
  </style>